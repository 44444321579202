<template>
    <ShipDialog
        :showTab="showTab"
        width="700px"
        :visible.sync="visibleSync"
        title="客户公司设置"
    >
        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                flex-direction: column;
            "
        >
            <el-row type="flex" justify="space-between" style="width:100%">
                <el-row>
                    <el-input
                        size="small"
                        v-model="name"
                        clearable
                        style="width: 200px;margin-right: 12px;"
                        placeholder="请输入客户公司名称"
                        @change="handleFilterNameChange"
                        @keyup.enter.native="handleQueryTable"
                    ></el-input>
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="handleQueryTable('search')"
                        :loading="tableLoading"
                    >
                        查询
                    </el-button>
                    <el-button
                        size="small"
                        @click="handleReset"
                        :loading="tableLoading"
                    >重置</el-button>
                </el-row>
                <el-row>
                    <el-button type="primary" plain size="small" @click="goClientManage" id="clientCompanyManageGuide">管理</el-button>
                    <el-button type="primary" size="small" @click="handleAdd" id="clientCompanyAddGuide">新增</el-button>
                </el-row>
            </el-row>
            <el-table
                style="margin-top: 20px"
                v-loading="tableLoading"
                :data="tableData"
            >
                <el-table-column
                    prop="clientName"
                    label="客户公司名称"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="contacts"
                    label="联系人"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="contactsPhone"
                    label="联系人手机"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    align="center"
                ></el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            v-if="scope.row.status === 0"
                            type="success"
                            size="small"
                        >
                            启用中
                        </el-tag>
                        <el-tag v-else type="danger" size="small">
                            已停用
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="action"
                    label="操作"
                    align="center"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-link
                            type="primary"
                            @click="handleTuConfirm(scope.row)"
                        >
                            选择
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination" style="margin-top: 30px">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 10"
                ></el-pagination>
            </div>
        </div>
        <AddClientCompanyDialog
            ref="addClientCompanyDialogRef"
            @confirm="handleRefreshTable"
        />
        <!-- <DialogFooter
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
            :loading="loading"
            :showTab="showTab"
            top="50"
        ></DialogFooter> -->
    </ShipDialog>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { getClientCompanyList, setClientCompany } from "@/api/client";
import AddClientCompanyDialog from "@/views/user/components/client/components/AddClientCompanyDialog.vue";
import { apiCallback,setCompanyCache } from "@/utils";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
    name: "ChangeClientComanyDialog",
    props: ["showTab"],
    data() {
        return {
            visibleSync: false,
            id: 0,
            loading: false,
            tableData: [],
            tableLoading: false,
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            name: "",
        };
    },
    computed: {
        ...mapGetters(["user/clientCompanyListByName"]),
    },
    components: {
        ShipDialog,
        DialogFooter,
        AddClientCompanyDialog,
    },
    methods: {
        // 刷新客户公司列表
        // 查询客户公司列表
        async handleRefreshTable(type) {
            this.tableLoading = true;
            await setCompanyCache()
            this.tableLoading = false;
            this.handleQueryTable()
            // this.pageNo=1
            // this.pageSize=1000
            // getClientCompanyList({
            //     pageNo: this.pageNo,
            //     pageSize: this.pageSize,
            // }).then((res) => {
            //     const list = res.data.data.list;
            //     // 过滤出status为0的数据
            //     const isUseTableData = list.filter((item) => item.status === 0);
            //     // 将这个数据存入vuex
            //     this.$store.commit(
            //         "user/editClientUseComapnyList",
            //         isUseTableData,
            //     );
            //     this.tableLoading = false;
            //     this.handleQueryTable()
            // });
        },
        // 查询客户公司列表
        handleQueryTable(type) {
            const that = this;
            if (type === "search") {
                this.pageNo = 1;
            }
            return new Promise((resolve, reject) => {
                const curClientCompanyList = this['user/clientCompanyListByName'](this.name);
                if (curClientCompanyList.length === 0 && type !== "update") { 
                    // 如果没有数据，就请求接口
                    setCompanyCache().then(() => {
                        // 请求接口成功后，再次调用查询方法,并增加标识防止死循环
                        this.handleQueryTable('update').then(() => {
                            resolve();
                        });
                    });
                } else {
                    this.totalNum = curClientCompanyList.length;
                    this.tableData = curClientCompanyList;
                    resolve();
                }
            });
        },
        async open(item) {
            // 发送请求
            return new Promise((resolve, reject) => {
                this.handleQueryTable().then(() => {
                    this.id = item.id;
                    this.visibleSync = true;
                    this.$nextTick(() => { 
                        resolve();
                    })
                });
            });
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleFilterNameChange() {
            if (this.name === "") {
                // 清除输入框内容时，重新请求数据
                this.handleQueryTable();
            }
        },
        handleReset() {
            this.name = "";
            this.handleQueryTable();
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleQueryTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleQueryTable();
        },
        handleTuConfirm(row) {
            this.loading = true;
            setClientCompany({
                blnoId: this.id,
                companyId: row.id,
                companyName: row.clientName,
                port: this.showTab,
            })
                .then(() => {
                    this.$message.success("客户公司设置成功");
                    this.visibleSync = false;
                    this.$emit("confirm", row.clientName);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleAdd() {
            this.$refs.addClientCompanyDialogRef.handleAddDialog();
        },
        goClientManage(){
            this.$router.push("/user/client");
        }
    },
};
</script>
<style></style>
