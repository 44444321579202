import getMyDriver from "./myDriver";

const feedbackSteps = [
    {
        element: "#feedback-icon",
        popover: {
            title: "提示",
            description: "点击这里可以反馈问题",
        },
    },
    {
        element: "#feedback-input",
        popover: {
            title: "提示",
            description: "在这里输入您的问题",
        },
    },
];

const clientCompanySteps = [
    {
        element: "#clientCompanyGuideCell",
        popover: {
            title: "提示",
            description: "点击这里可以选择客户公司",
        },
    },
    {
        element: "#clientCompanyAddGuide",
        popover: {
            title: "提示",
            description: "点击这里可以添加客户公司",
        },
    },
    {
        element: "#clientCompanyManageGuide",
        popover: {
            title: "提示",
            description: "点击这里可以打开管客户公司管理页面",
        },
    },
    {
        element: "#clientCompany-filter-select",
        popover: {
            title: "提示",
            description: "绑定成功后可在此筛选客户公司",
        },
    },
];

const shipChangeNoAddSteps = [
    {
        element: ".mark-shipChange-icon",
        popover: {
            title: "提示",
            description:
                "【宁波港】添加船名航次后，可以点击此按钮标记换船，可以提高换船后的数据跟踪准确性",
        },
    },
];

const shipChangeNeedAddSteps = [
    {
        element: document.querySelector(".addVoyage_link"),
        popover: {
            title: "提示",
            description: "点击这里可以添加航次",
        },
    },
    {
        element: document.querySelector(".mark-shipChange-icon"),
        popover: {
            title: "提示",
            description:
                "【宁波港】添加船名航次后，可以点击此按钮标记换船，标记后会更快更新换船后的数据，标记成功后此图标会变为蓝色",
        },
    },
];

const addWharfSteps = [
    {
        element: "#addWharfGuideCell",
        popover: {
            title: "提示",
            description: "【宁波港】这里可以添加或修改码头",
        },
    },
];

const updateNotesSteps = [
    {
        element: ".update-notes-icon",
        popover: {
            title: "提示",
            description: "您可以点击这里查看所有更新记录",
        },
    },
];

const guideFunc = (type, options = {}) => {
    const driver = getMyDriver({
        onNextClick: (ele, step) => {
            if (options.onNextClick) {
                options.onNextClick(driver, step);
            } else {
                driver.moveNext();
            }
        },
        onCloseClick: (ele, step) => {
            if (options.onCloseClick) {
                options.onCloseClick(driver, step);
            } else {
                driver.destroy();
            }
        },
        onPrevClick: (ele, step) => {
            if (options.onPrevClick) {
                options.onPrevClick(driver, step);
            } else {
                driver.movePrevious();
            }
        },
    });
    let step = [];
    switch (type) {
        case "feedback":
            step = feedbackSteps;
            break;
        case "clientCompany":
            step = clientCompanySteps;
            break;
        case "shipChangeNoAdd":
            step = shipChangeNoAddSteps;
            break;
        case "shipChangeNeedAdd":
            step = shipChangeNeedAddSteps;
            break;
        case "addWharf":
            step = addWharfSteps;
            break;
        case "allNote3":
            step = [
                ...feedbackSteps,
                ...clientCompanySteps,
                ...shipChangeNoAddSteps,
                ...addWharfSteps,
                ...updateNotesSteps,
            ];
    }
    if (step.length > 0) {
        driver.setSteps(step);
        driver.drive();
    }
};

export { guideFunc };
