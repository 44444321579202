var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"setting_dialog_layout",attrs:{"visible":_vm.dialogTableVisible,"center":"","show-close":false,"close-on-press-escape":false,"close-on-click-modal":false,"attach-to-body":""},on:{"update:visible":function($event){_vm.dialogTableVisible=$event},"confirm":()=>{
        this.$emit('confirm')
    },"close":()=>{
        this.$emit('close')
    },"cancel":()=>{
        this.$emit('cancel')
    }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"id-role"},[_vm._v(_vm._s(_vm.title))]),_c('img',{staticClass:"setting-img",attrs:{"src":_vm.settingImg,"alt":"","width":"50"}}),_c('img',{staticClass:"pop-right",attrs:{"src":_vm.popupRight,"alt":"","width":"250"}})])]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }