<template>
    <ShipDialog
        class="detail_dialog"
        width="80%"
        @close="closeDialog"
        top="3vh"
        :visible.sync="dialogTableVisible"
        center
        :needImg="false"
        title="详情"
        :showTab="showTab"
    >
        <div class="button_class">
            <div class="menu-wrap">
                <div>快捷导航：</div>
                <el-radio-group
                    size="mini"
                    v-model="activeMenu"
                    @change="handleAnchorChange"
                    @click="handleAnchorChange"
                >
                    <el-radio-button label="提单信息"></el-radio-button>
                    <el-radio-button label="船期信息"></el-radio-button>
                    <el-radio-button label="箱动态"></el-radio-button>
                    <el-radio-button label="预配舱单"  v-if="!['SZ'].includes(showTab)"></el-radio-button>
                    <el-radio-button
                        v-if="!['QD', 'TJ','SZ'].includes(showTab)"
                        label="海关放行信息"
                    ></el-radio-button>
                </el-radio-group>
            </div>
            <div>
                <DialogFooter
                    @confirm="go2Detail(detail)"
                    @cancel="dialogTableVisible = false"
                    :showTab="showTab"
                    :confirmOptions="{
                        size: 'small',
                    }"
                    :cancelOptions="{
                        size: 'small',
                    }"
                    cancelText="关闭"
                    style="gap:10px"
                    :confirmText="detail.id?'网页显示':''"
                    top="0"
                ></DialogFooter>
            </div>
        </div>
        <div class="detail_dialog_content">
            <DetailContent
                ref="detailContentRef"
                :queryData="detail"
                v-bind="$attrs"
                @change="changeFlag = true"
            ></DetailContent>
        </div>
    </ShipDialog>
</template>

<script>
import DetailContent from "./DetailContent/index.vue";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    data() {
        return {
            dialogTableVisible: false,
            detail: {},
            showTab: "NB",
            activeMenu: "",
            scrollTirgger: "",
            changeFlag: false
        };
    },
    components: {
        DetailContent,
        ShipDialog,
        DialogFooter,
    },
    watch: {
        dialogTableVisible(val) {
            if (val) {
                this.showTab = localStorage.getItem("showTab") || this.showTab;
                this.$nextTick(() => {
                    const scrollContainer = document.querySelector(
                        ".detail_dialog_content",
                    );
                    scrollContainer.addEventListener("scroll", () => {
                        if (this.scrollTirgger !== "click") {
                            this.activeMenu = "";
                        } else {
                            this.scrollTirgger = "";
                        }
                    });
                });
            }
        },
    },
    methods: {
        handleAnchorChange() {
            let anchorId = "";
            switch (this.activeMenu) {
                case "提单信息":
                case "船期信息":
                    anchorId = "logistics-detail-top";
                    break;
                case "箱动态":
                    anchorId = "logistics-detail-box";
                    break;
                case "海关放行信息":
                    anchorId = "logistics-detail-release";
                    break;
                case "预配舱单":
                    anchorId = "logistics-detail-manifest";
                    break;
            }
            if (anchorId) {
                this.scrollTirgger = "click";
                document.querySelector(`#${anchorId}`).scrollIntoView();
            }
        },
        Init(detail) {
            // 参数获取
            this.dialogTableVisible = true;
            this.changeFlag = false;
            this.detail = {
                ...detail,
            };
            this.$nextTick(() => { 
                if (detail.id) { 
                    this.$refs.detailContentRef.Init(detail)
                }
            })
        },
        closeDialog() {
            this.detail = {};
            if (this.changeFlag) { 
                this.$emit("refreshDataList");
            }
        },
        go2Detail(itemInfo) {
            // console.log("itemInfo=" + itemInfo.blno);
            const newpage = this.$router.resolve({
                path: "/detail",
                query: {
                    data: encodeURIComponent(JSON.stringify(itemInfo)),
                },
            });
            window.open(newpage.href, "_blank");
        },
        handleAnchorClick(type) {
            document
                .querySelector("#logistics-detail-manifest")
                .scrollIntoView();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
.detail_dialog{
  &.el-dialog__wrapper{
    width: 100%;
  }
  .menu-wrap{
    display: flex;
    align-items: center;
  }
  .detail_dialog_content{
    max-height: 78vh;
    overflow: auto;
  }
  .el-dialog__body{
    padding-top:16px;
  }
  .button_class {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }
}
</style>
