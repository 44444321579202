<template>
    <span class="table-remark-cell-wrap">
        <span
            v-if="!rowData.remark"
            @click="addRemark(rowData)"
            class="table_link_text_bold"
        >
            添加备注
        </span>
        <div
            v-else
            :class="{
                'logistics-remark-wrap': true,
                multiLine:
                    rowData.remark.length >
                    14,
            }"
        >
            <i
                @click="addRemark(rowData)"
                class="el-icon-edit"
            ></i>
            {{ rowData.remark }}
        </div>
    </span>
</template>

<script>
export default {
    name: 'RemarkCell',
    emits:['edit'],
    props: {
        rowData: {
            type: Object
        },
    },
    methods: {
        addRemark(data) {
            this.$emit('edit',data)
        }
    }
}
</script>

<style lang="stylus" scoped>
.table-remark-cell-wrap{
    .table_link_text_bold {
        color: link-text-color-primary;
        font-weight:bold;
        font-size:14px;
        cursor: pointer;
        user-select: none;
        &:hover{
            opacity: 0.8;
        }
    }
    .logistics-remark-wrap{
        width: 100%;
        white-space: wrap;
        i{
            cursor: pointer;
        }
        &.multiLine{
            i{
                font-size: 14px;
            }
            line-height: 18px;
            font-size: 12px;
        }
    }
}
</style>