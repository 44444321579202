<template>
    <div class="top_div" :class="`${showTab}_banner`">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "TopBanner",
    props: {
        showTab: {
            type: String,
            default: "NB",
        },
    },
};
</script>
<style lang="stylus">
.NB_banner {
    background-image: linear-gradient(
        var(--NB-RED-FIRST),
        var(--NB-RED-SECOND),
        var(--NB-RED-FIRST)
    )
}
.SH_banner {
    background-image: linear-gradient(
        var(--SH-BLUE-FIRST),
        var(--SH-BLUE-SECOND),
        var(--SH-BLUE-FIRST)
    );
}
.QD_banner {
    background-image: linear-gradient(
        var(--QD-GREEN-FIRST),
        var(--QD-GREEN-SECOND),
        var(--QD-GREEN-FIRST)
    );
}
.TJ_banner {
    background-image: linear-gradient(
        var(--TJ-YELLOW-FIRST),
        var(--TJ-YELLOW-SECOND),
        var(--TJ-YELLOW-FIRST)
    );
}
.XM_banner {
    background-image: linear-gradient(
        var(--XM-PURPLE-FIRST),
        var(--XM-PURPLE-SECOND),
        var(--XM-PURPLE-FIRST)
    );
}
.SZ_banner {
    background-image: linear-gradient(
        var(--SZ-ORANGE-FIRST),
        var(--SZ-ORANGE-SECOND),
        var(--SZ-ORANGE-FIRST)
    );
    .header_footer{
        color: #ffffff !important;
    }
}
</style>
