<template>
    <ShipDialog
        title="添加客户公司"
        :visible.sync="dialogVisible"
        showTab="NB"
        width="500px"
        :needImg="false"
        v-if="dialogVisible"
        append-to-body  
    >
        <el-form
            ref="formRef"
            :model="formData"
            label-width="110px"
            :rules="formRules"
        >
            <el-form-item prop="clientName" label="客户公司">
                <el-input
                    clearable
                    v-model="formData.clientName"
                    placeholder="请输入客户公司名称"
                    class="inline-input"
                ></el-input>
            </el-form-item>
            <el-form-item prop="contacts" label="联系人">
                <el-input
                    clearable
                    v-model="formData.contacts"
                    placeholder="请输入联系人"
                    class="inline-input"
                ></el-input>
            </el-form-item>
            <el-form-item prop="contactsPhone" label="联系人手机">
                <el-input
                    clearable
                    v-model="formData.contactsPhone"
                    placeholder="请输入联系人手机"
                    class="inline-input"
                ></el-input>
            </el-form-item>
            <el-form-item prop="remark" label="备注">
                <el-input
                    clearable
                    v-model="formData.remark"
                    placeholder="请输入备注"
                    class="inline-input"
                ></el-input>
            </el-form-item>
            <el-form-item label-width="0">
                <DialogFooter
                    @confirm="handleAddClientCompany"
                    @cancel="handleClose"
                    :loading="loading"
                    showTab="NB"
                ></DialogFooter>
            </el-form-item>
        </el-form>
    </ShipDialog>
</template>
<script>
import { addClientCompany } from "@/api/client";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    data() {
        return {
            loading: false,
            dialogVisible: false,
            formData: {
                clientName: "",
                contacts: "",
                contactsPhone: "",
                remark: "",
            },
            formRules: {
                clientName: [
                    {
                        required: true,
                        message: "请输入客户公司名称",
                        trigger: "blur",
                    },
                ],
                contacts: [
                    {
                        required: true,
                        message: "请输入联系人",
                        trigger: "blur",
                    },
                ],
                contactsPhone: [
                    {
                        required: true,
                        message: "请输入联系人手机",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    computed: {},
    methods: {
        handleClose() {
            this.dialogVisible = false;
        },

        handleAddClientCompany() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    addClientCompany(this.formData)
                        .then(() => {
                            this.$message.success("新增成功");
                            this.dialogVisible = false;
                            this.$emit("confirm");
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },

        // 新增
        handleAddDialog() {
            this.formData = {
                clientName: "",
                contacts: "",
                contactsPhone: "",
                remark: "",
            };
            this.dialogVisible = true;
        },
    },
};
</script>
