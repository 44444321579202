<template>
    <ShipDialog
        :title="dialogTitle"
        :visible.sync="visible"
        @close="closeHandle"
        :showTab="showTab"
        width="700px"
    >
        <div style="display: flex; justify-content: end">
            <el-dropdown @command="handleGetTempFile">
                <el-button type="success" size="small" plain>
                    获取模板
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">
                        船名航次在同一格
                    </el-dropdown-item>
                    <el-dropdown-item command="2">
                        船名航次在不同格
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-upload
            drag
            :action="url"
            :data="uploadData"
            :before-upload="beforeUploadHandle"
            :on-success="successHandle"
            :headers="uploadHeaders"
            multiple
            accept=".xls,.xlsx"
            :file-list="fileList"
            style="text-align: center"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
            </div>
            <template slot="tip">
                <div class="el-upload__tip">
                    只支持Excel表格文件！
                    <span
                        v-if="uploadCode === 'SH' && pageType === 'logistics'"
                        style="color: #e6a23c"
                    >
                        (上海提单订阅每票消耗1火币，请注意关注帐户余额)
                    </span>
                </div>
            </template>
        </el-upload>
    </ShipDialog>
</template>

<script>
import { getToken, getCompanyId, getRole } from "@/utils/auth";
import { getNameByCode } from "@/utils";
import ShipDialog from "@/components/layout/ShipDialog.vue";
export default {
    data() {
        return {
            visible: false,
            url: "",
            num: 0,
            successNum: 0,
            fileList: [],
            type: "",
            uploadCode: "NB",
            pageType: "logistics", // 当前页面类型（物流、船期）
            data: {
                code: this.$store.state.other.systemKey,
            },
            uploadHeaders: { Authorization: getToken() },
        };
    },
    components: {
        ShipDialog,
    },
    computed: {
        uploadData() {
            // 把companyId和role传给后台
            return getCompanyId()
                ? {
                      code: this.uploadCode,
                      companyId: getCompanyId(),
                      role: getRole(),
                  }
                : {
                      code: this.uploadCode,
                      role: getRole(),
                  };
        },
        dialogTitle() {
            const name = getNameByCode(this.uploadCode);
            const showName = `批量跟踪（${name}）`;
            return showName;
        },
    },
    props: ["showTab"],
    methods: {
        // 上传物流跟踪批量订阅
        init() {
            this.uploadCode = "NB";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlno/v2/uploadBlnoExcel";
            this.visible = true;
        },

        // 上传上海物流跟踪批量订阅
        initSH() {
            this.uploadCode = "SH";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlnoSh/uploadBlnoExcel";
            this.visible = true;
        },
        // 上传青岛物流跟踪批量订阅
        initQD() {
            this.uploadCode = "QD";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlnoQd/uploadBlnoExcel";
            this.visible = true;
        },

        // 上传天津物流跟踪批量订阅
        initTJ() {
            this.uploadCode = "TJ";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlnoTj/uploadBlnoExcel";
            this.visible = true;
        },

        initXM() {
            this.uploadCode = "XM";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlnoXm/uploadBlnoExcel";
            this.visible = true;
        },

        initSZ(){
            this.uploadCode = "SZ";
            this.pageType = "logistics";
            this.url = this.$base_url + "/blno/userBlnoSz/uploadBlnoExcel";
            this.visible = true;
        },

        //船期批量订阅
        initShipSubscrib() {
            this.uploadCode = "NB";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/userBlno/uploadVesselsExcel";
            this.visible = true;
        },
        //上海船期批量订阅
        initShVessels() {
            this.uploadCode = "SH";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/shVessels/uploadVesselsExcel";
            this.visible = true;
        },

        // 青岛船期批量订阅
        initQDVessels() {
            this.uploadCode = "QD";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/qdVessels/uploadVesselsExcel";
            this.visible = true;
        },

        // 天津船期批量订阅
        initTJVessels() {
            this.uploadCode = "TJ";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/tjVessels/uploadVesselsExcel";
            this.visible = true;
        },

        // 厦门船期批量订阅
        initXMVessels() {
            this.uploadCode = "XM";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/xmVessels/uploadVesselsExcel";
            this.visible = true;
        },

        // 深圳船期批量订阅
        initSZVessels() {
            this.uploadCode = "SZ";
            this.pageType = "ship";
            this.url = this.$base_url + "/vessels/szVessels/uploadVesselsExcel";
            this.visible = true;
        },

        // 上传之前
        beforeUploadHandle(file) {
            // console.log("file type=",file.name);
            if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
                this.num++;
            } else {
                this.$message.error("请上传Excel表格文件！");
                return false;
            }
        },
        // 上传成功
        successHandle(response, file, fileList) {
            this.fileList = fileList;
            this.successNum++;
            if (!response.message.match("订阅")) {
                this.$AlertTipsDialog({
                    msg: response.message,
                });
            } else {
                this.$message.success(response.message);
            }
            this.visible = false;
        },
        // 弹窗关闭时
        closeHandle() {
            let p = [];
            if (this.fileList[0]) {
                p["url"] = this.fileList[0].response.filePath;
            }
            p["type"] = this.type;
            this.fileList = [];
            this.$emit("refreshDataList", p);
        },
        handleGetTempFile(type) {
            let needDownId = Number(type);
            if (this.pageType === "ship") {
                needDownId += 2;
            }
            if (this.showTab === "QD" && this.pageType === "logistics") {
                needDownId += 4;
            }
            if (this.showTab === "XM") {
                needDownId += 6;
            }
            window.open(this.$excel_url + "/" + needDownId, "_blank");
        },
    },
};
</script>
