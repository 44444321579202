<template>
    <div :class="{'my-icon-wrap':true,'clickable':clickable,[customClass]:customClass}" :id="customId" @click="handleClick">
        <Icon :icon="this.icon" :style="iconStyle"></Icon>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
    name: 'MyIcon',
    props: {
        size: {
            type:Number
        },
        color: {
            type:String
        },
        icon: {
            type:String
        },
        clickable: {
            type:Boolean,
            default: false
        },
        theme: {
            type:String,
            default: 'default'
        },
        customClass: {
            type:String
        },
        customId: {
            type:String
        }
    },
    components: {
        Icon
    },
    computed: {
        iconStyle() { 
            let cacheStyle = {
                
            };
            if (this.size) { 
                cacheStyle.fontSize = `${this.size}px`;
            }
            if (this.color) { 
                cacheStyle.color = this.color;
            }
            if (this.theme !== 'default') {
                const colorEnum = {'primary':'#409EFF','success':'#67C23A','warning':'#E6A23C','danger':'#F56C6C'};
                cacheStyle.color = colorEnum[this.theme];
            }
            return cacheStyle
        }
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
}
</script>

<style lang="stylus" scoped>
.my-icon-wrap{
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0 4px;
    &.clickable{
        cursor:pointer;
        opacity:0.8;
    }
}
</style>