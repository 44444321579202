import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const initOptions = {
    animate: true, // Animate while changing highlighted element 是否在切换时使用动画
    opacity: 0.75, // Background opacity (0 means only popovers and without overlay) 覆盖阴影透明度
    padding: 10, // Distance of element from around the edges 高亮与高亮节点的间距大小
    allowClose: true, // Whether clicking on overlay should close or not 是否允许点击覆盖阴影关闭 默认允许
    overlayClickNext: false, // Should it move to next step on overlay click 是否点击覆盖阴影时下一步
    doneBtnText: "完成", // Text on the final button 完成按钮的文字
    closeBtnText: "关闭", // Text on the close button for this step 关闭按钮的文字
    nextBtnText: "下一步", // Next button text for this step 下一步按钮的文字
    prevBtnText: "上一步", // Previous button text for this step 上一步按钮文字
    showButtons: false, // Do not show control buttons in footer 是否在底部显示按钮
    keyboardControl: true, // Allow controlling through keyboard (escape to close, arrow keys to move) 是否允许键盘控制 （ESC 关闭， 方向键 控制进度）
    scrollIntoViewOptions: {}, // We use `scrollIntoView()` when possible, pass here the options for it if you want any
};

const getMyDriver = (options) => {
    return driver({
        ...initOptions,
        ...options,
    });
};

export default getMyDriver;
