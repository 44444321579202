<template>
    <ShipDialog
        :showTab="showTab"
        width="500px"
        size="small"
        :visible.sync="visibleSync"
        title="换船标识修改"
    >
        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                margin-top: 20px;
            "
        >
            <el-switch
                v-model="flag"
                active-text="换船"
                inactive-text="未换船"
                active-color="#c11c20"
                inactive-color="#d3d3d3"
                class="fcw-change-ship-switch"
            ></el-switch>
        </div>
        <DialogFooter
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
            :loading="loading"
            :showTab="showTab"
            top="50"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { setShipChangeFlag } from "@/api/ship";
export default {
    name: "ChangeShipDialog",
    props: ["showTab"],
    data() {
        return {
            visibleSync: false,
            id: 0,
            loading: false,
            flag: false,
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    methods: {
        open(item) {
            const { id, changeVessels } = item;
            this.id = id;
            this.flag = Boolean(changeVessels==='true');
            this.visibleSync = true;
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            this.loading = true;
            setShipChangeFlag({
                id: this.id,
                flag: this.flag,
            })
                .then(() => {
                    this.$message.success("换船标识修改成功");
                    this.visibleSync = false;
                    this.$emit("confirm", this.flag);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.fcw-change-ship-switch{
    /deep/ .el-switch__label{
        
        span{
            font-size:20px !important;
        }
    }
    /deep/ .is-active{
        color:#b87100 !important;
    }
}
</style>